import {
  DocumentFileDeactivationDialogComponent,
  DocumentFileDeativationDialogData
} from '../components/document-file-deactivation-dialog/document-file-deactivation-dialog.component';
import {Observable} from 'rxjs';
import {AbstractDocumentDialogsManagerService, isStorageUnitObject} from '|shared';
import {DocumentDto, FileDto, StorageUnitDto} from '|api/document';
import {inject, Injectable} from '@angular/core';
import {
  StorageUnitInsertDialogComponent,
  StorageUnitInsertDialogData
} from '|modules/documents/modules/document-shared/components/storage-unit-insert-dialog/storage-unit-insert-dialog.component';
import {
  RegistryOfficeTransferrableObject,
  TransferToRegistryOfficeDialogComponent
} from '|modules/documents/modules/document-shared/components/transfer-to-registry-office-dialog/transfer-to-registry-office-dialog.component';
import {
  TransferActivityTakeoverDialogComponent
} from '|modules/documents/modules/document-shared/components/transfer-activity-takeover-dialog/transfer-activity-takeover-dialog.component';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';
import {
  SuspendOrResumeSettlementDialogComponent,
  SuspendOrResumeSettlementDialogData
} from '|modules/documents/modules/document-shared/components/suspend-or-resume-settlement-dialog/suspend-or-resume-settlement-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {
  EditRetentionYearDialogComponent,
  EditRetentionYearDialogData
} from '|modules/documents/modules/document-shared/components/edit-retention-year-dialog/edit-retention-year-dialog.component';

@Injectable()
export class DocumentDialogsManagerService implements AbstractDocumentDialogsManagerService {

  private modalService = inject(IczModalService);

  openDeactivationDialog(documentData: DocumentDto): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, DocumentFileDeativationDialogData>({
      component: DocumentFileDeactivationDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: 'Zrušení dokumentu',
      },
      data: {
        isDocument: true,
        subject: documentData.subject,
        entityId: documentData.id,
      },
    });
  }

  openStorageUnitInsertDialog(selectedObjects: (DocumentDto | FileDto)[], moveFromStorageUnitId?: number): Observable<boolean> {
    const isMove = Boolean(moveFromStorageUnitId);

    return this.modalService.openComponentInModal<boolean, StorageUnitInsertDialogData>({
      component: StorageUnitInsertDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: isMove ? 'Přesunutí do jiné ukládací jednotky' : 'Vložení do ukládací jednotky',
        disableAutoMargin: true,
      },
      data: {
        storageUnitId: moveFromStorageUnitId,
        selectedObjects,
        isMove,
      }
    });
  }

  openCurrentStorageUnitInsertDialog(selectedStorageUnit: StorageUnitDto): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, StorageUnitInsertDialogData>({
      component: StorageUnitInsertDialogComponent,
      modalOptions: {
        width: 1030,
        height: 825,
        titleTemplate: 'Vložení do {{storageUnitNumber}} - {{storageUnitName}}',
        titleTemplateContext: {
          storageUnitNumber: selectedStorageUnit.storageUnitNumber,
          storageUnitName: selectedStorageUnit.name,
        },
        disableAutoMargin: true,
      },
      data: {
        storageUnitId: selectedStorageUnit.id,
        isMove: false,
      }
    });
  }

  openTransferToRegistryOfficeDialog(selectedObjects: RegistryOfficeTransferrableObject[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, RegistryOfficeTransferrableObject[]>({
      component: TransferToRegistryOfficeDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: selectedObjects.length > 1 ? 'Hromadné předání do spisovny ({{count}})' : 'Předání {{refNumber}} do spisovny',
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: isStorageUnitObject(selectedObjects[0]) ? (selectedObjects[0] as StorageUnitDto).storageUnitNumber! : (selectedObjects[0] as DocumentDto).refNumber!,
        }
      },
      data: selectedObjects
    });
  }

  openTransferTakeoverByRegistryOfficeDialog(selectedActivities: SearchRecordSourceRegistryOfficeTransferActivityDto[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, any>({
      component: TransferActivityTakeoverDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: selectedActivities.length > 1 ? 'Hromadné převzetí do spisovny ({{count}})' : 'Převzetí {{entityName}} do spisovny',
        titleTemplateContext: {
          count: String(selectedActivities.length),
          entityName: selectedActivities[0].entityName ?? selectedActivities[0].subject ?? selectedActivities[0].name,
        }
      },
      data: selectedActivities
    });
  }

  openSuspendSettlementDialog(selectedObjects: (DocumentDto | FileDto)[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, SuspendOrResumeSettlementDialogData>({
      component: SuspendOrResumeSettlementDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: selectedObjects.length > 1 ? 'Hromadné přerušení řízení ({{count}})' : 'Přerušení řízení {{refNumber}}',
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: selectedObjects[0].refNumber!,
        }
      },
      data: {
        isSuspend: true,
        selectedObjects
      }
    });
  }

  openResumeSettlementDialog(selectedObjects: (DocumentDto | FileDto)[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, SuspendOrResumeSettlementDialogData>({
      component: SuspendOrResumeSettlementDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: selectedObjects.length > 1 ? 'Hromadné obnovení řízení ({{count}})' : 'Obnovení řízení {{refNumber}}',
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: selectedObjects[0].refNumber!,
        }
      },
      data: {
        isSuspend: false,
        selectedObjects
      }
    });
  }

  openRetentionYearEditDialog(selectedObjects: (DocumentDto | FileDto)[], editCheckYear: boolean): Observable<boolean> {
    let titleTemplate = '';
    if (selectedObjects.length > 1) {
      if (editCheckYear) {
        titleTemplate = 'Hromadná editace lhůty kontroly událostí ({{count}})';
      } else {
        titleTemplate = 'Hromadná editace roku spouštěcí událostí ({{count}})';
      }
    } else {
      if (editCheckYear) {
        titleTemplate = 'Editace lhůty kontroly událostí {{refNumber}}';
      } else {
        titleTemplate = 'Editace roku spouštěcí událostí {{refNumber}}';
      }
    }

    return this.modalService.openComponentInModal<boolean, EditRetentionYearDialogData>({
      component: EditRetentionYearDialogComponent,
      modalOptions: {
        width: 600,
        height: 400,
        titleTemplate,
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: selectedObjects[0].refNumber!,
        }
      },
      data: {
        editCheckYear,
        selectedObjects
      }
    });
  }
}
