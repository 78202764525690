import {inject, Injectable} from '@angular/core';
import {IczModalService} from '@icz/angular-modal';
import {tap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {OwnOfficeDeskConsignmentElasticDto} from '|api/sad';
import {
  ManualPostDialogComponent
} from '|modules/office-desk/modules/office-desk-dialogs/components/manual-post-dialog/manual-post-dialog.component';
import {
  AbstractManualPostDialogService,
  ConsignmentsToastService,
  ConsignmentToastData,
  CounterTypeGroup,
  MainMenuCountsService,
  ManualPostDialogResult,
  ManualPostDialogResultType
} from '|shared';

export interface ManualPostDialogData {
  documentId: number;
  consignment: OwnOfficeDeskConsignmentElasticDto;
}

@Injectable({
  providedIn: 'root'
})
export class ManualPostDialogService implements AbstractManualPostDialogService {

  private modalService = inject(IczModalService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private consignmentsToastService = inject(ConsignmentsToastService);

  openManualPostDialog(documentId: number, consignment: OwnOfficeDeskConsignmentElasticDto) {
    return this.modalService.openComponentInModal<Nullable<ManualPostDialogResult>, ManualPostDialogData>({
      component: ManualPostDialogComponent,
      modalOptions: {
        titleTemplate: 'Vyvěsit',
        width: 800,
        height: 600,
      },
      data: {
        documentId,
        consignment,
      },
    }).pipe(
      tap(result => {
        if (result) {
          const toastData: ConsignmentToastData = {
            [InternalNotificationKey.DOCUMENT_ID]: documentId,
            [InternalNotificationKey.CONSIGNMENT_ID]: result.consignment.id,
            [InternalNotificationKey.CONSIGNMENT_NAME]: result.consignment.label!,
            [InternalNotificationKey.CONSIGNMENT_UID]: result.consignment.uid!.uid!,
          };

          if (result.resultType === ManualPostDialogResultType.POSTED) {
            this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
            this.consignmentsToastService.dispatchConsignmentManuallyPosted(toastData);
          }
        }
      }),
    );
  }

}
