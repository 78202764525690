import {
  DocumentFileDeactivationDialogComponent,
  DocumentFileDeativationDialogData,
} from '../components/document-file-deactivation-dialog/document-file-deactivation-dialog.component';
import {AbstractFileDialogsManagerService} from '|shared';
import {FileDto} from '|api/document';
import {Observable} from 'rxjs';
import {FileSettleDialogComponent} from '../components/file-settle-dialog/file-settle-dialog.component';
import {FileCloseDialogComponent} from '../components/file-close-dialog/file-close-dialog.component';
import {inject, Injectable} from '@angular/core';
import {IczModalService} from '@icz/angular-modal';

@Injectable()
export class FileDialogsManagerService implements AbstractFileDialogsManagerService {

  private modalService = inject(IczModalService);

  openDeactivationDialog(fileData: FileDto): Observable<Nullable<boolean>> {
    return this.modalService.openComponentInModal<boolean, DocumentFileDeativationDialogData>({
      component: DocumentFileDeactivationDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: 'Zrušení spisu',
      },
      data: {
        isDocument: false,
        subject: fileData.subject,
        entityId: fileData.id,
      },
    });
  }

  openFileSettleDialog(fileData: FileDto[]): Observable<Nullable<boolean>> {
    return this.modalService
      .openComponentInModal<boolean, FileDto[]>({
        component: FileSettleDialogComponent,
        modalOptions: {
          width: 1000,
          height: 825,
          titleTemplate: fileData.length > 1 ? 'Vyřízení spisů ({{count}})' : 'Vyřízení spisu {{refNumber}}',
          titleTemplateContext: {
            refNumber: fileData[0].refNumber || '',
            count: String(fileData.length),
          },
          disableAutoMargin: true,
        },
        data: fileData,
      });
  }

  openFileCloseDialog(fileData: FileDto[]): Observable<Nullable<boolean>> {
    return this.modalService.openComponentInModal<boolean, FileDto[]>({
      component: FileCloseDialogComponent,
      modalOptions: {
        width: 1000,
        height: 825,
        titleTemplate: fileData.length > 1 ? 'Uzavření spisů ({{count}})' : 'Uzavření spisu {{fileSubject}}',
        titleTemplateContext: {
          fileSubject: fileData[0].subject,
          count: String(fileData.length),
        },
        disableAutoMargin: true,
      },
      data: fileData,
    });
  }

}
