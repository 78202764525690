import {inject, NgModule} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {STATIC_ENVIRONMENT} from '|environments/environment';
import {
  ActiveModuleService,
  ApplicationRoute,
  ApplicationUnavailablePageComponent,
  DocumentsRoute,
  getApplicationModuleByUrl,
  guardBackendStatus,
  guardUserAuthenticated,
  IczRoutes,
  LoginPageComponent,
  NetworkUnavailablePageComponent,
  NotFoundPageComponent,
  PlaceSelectionPageComponent,
  ThemeOutletComponent,
  WaitingToBeComponent
} from '|shared';

const routes: IczRoutes = [
  {
    path: '',
    component: ThemeOutletComponent,
    children: [
      {path: '', redirectTo: STATIC_ENVIRONMENT.defaultRoute, pathMatch: 'full'},
      {path: ApplicationRoute.DOCUMENTS, redirectTo: `${ApplicationRoute.DOCUMENTS}/${DocumentsRoute.IN_PROGRESS}`, pathMatch: 'full'},
      {path: ApplicationRoute.WAITING_TO_BE, component: WaitingToBeComponent, data: {skipHistoryBit: true}},
      {path: ApplicationRoute.NETWORK_UNAVAILABLE, component: NetworkUnavailablePageComponent, data: {skipHistoryBit: true}},
      {path: ApplicationRoute.APPLICATION_UNAVAILABLE, component: ApplicationUnavailablePageComponent, data: {skipHistoryBit: true}},
      {
        path: ApplicationRoute.LOGIN,
        canActivate: [guardBackendStatus()],
        component: LoginPageComponent,
        data: {skipHistoryBit: true},
      },
      {
        path: ApplicationRoute.PLACE_SELECT,
        canActivate: [guardUserAuthenticated(true), guardBackendStatus()],
        component: PlaceSelectionPageComponent,
        data: {
          skipHistoryBit: true,
        } ,
      },
      {
        path: ApplicationRoute.DOCUMENTS,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/documents/documents.module'),
      },
      {
        path: ApplicationRoute.FILING_OFFICE,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/filing-office/filing-office.module'),
      },
      {
        path: ApplicationRoute.REGISTRY_OFFICE,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/registry-office/registry-office.module'),
      },
      {
        path: ApplicationRoute.DISPATCH,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/dispatch/dispatch.module'),
      },
      {
        path: ApplicationRoute.OFFICE_DESK,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/office-desk/office-desk.module'),
      },
      {
        path: ApplicationRoute.UNIT,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/unit/unit.module'),
      },
      {
        path: ApplicationRoute.SHARED_FOLDERS,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/shared-folders/shared-folders.module'),
      },
      {
        path: ApplicationRoute.AGENDA_TRANSFER,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/agenda-transfer/agenda-transfer.module'),
      },
      {
        path: ApplicationRoute.DEMO,
        loadChildren: () => import('./modules/demo/demo.module'),
      },
      {
        path: ApplicationRoute.ADMIN,
        canActivate: [guardUserAuthenticated()],
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/admin/admin.module'),
      },
      {
        path: ApplicationRoute.CONFIG,
        canActivateChild: [guardBackendStatus()],
        loadChildren: () => import('./modules/config/config.module'),
      },
      {path: '**', component: NotFoundPageComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {

  private router = inject(Router);
  private activeModuleService = inject(ActiveModuleService);
  private loadingIndicatorService = inject(LoadingIndicatorService);

  constructor() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          !event.url.includes(ApplicationRoute.WAITING_TO_BE) &&
          !event.url.includes(ApplicationRoute.APPLICATION_UNAVAILABLE)
        ) {
          this.activeModuleService.activeModule = getApplicationModuleByUrl(event.url);
        }

        this.loadingIndicatorService.clearLoadings();
      }
    });
  }

}
