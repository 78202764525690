@if (loadingService.isLoading$(this) | async) {
  <div class="inaccessible-overlay col global-loading">
    <icz-spinner></icz-spinner>
    @if (globalLoadingService.globalLoadingDescription; as globalLoadingDescription) {
      <br>
        {{ globalLoadingDescription | translate }}
      }
    </div>
  }

  <router-outlet class="router-outlet-flex-container"></router-outlet>
